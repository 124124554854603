import sbjs from 'sourcebuster'
import $ from 'jquery'
import {detectDevice, getCookie, hubEvent, pushFBEvent} from './helpers'

function simpleProcessInit () {
  let leadFromLS = localStorage.getItem('leadId')

  function ccIframeInit () {
    // if (getCookie('abandoned_visit_by_pxl')) {
    //   $('#loader').fadeOut()
    //   return
    // }
    let a = 'cc_iframe'
    let b = document.createElement('iframe')
    b.id = a
    document.body.appendChild(b)
    document.getElementById(a).style.display = 'none'
    document.getElementById(a).src = 'https://clicfin.com/?gp=1'
    let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent"
    let eventer = window[eventMethod]
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message"
    eventer(messageEvent, function (e) {
      let data = (e.data && typeof e.data === 'object') ? e.data : {}
      if (data.name === 'cc' && data.data) {
        if (data.data.li) {
          localStorage.setItem('leadId', data.data.li)
          simpleProcessToFeed(data.data.li, 'pixel')
        } else {
          // we don't have lead_id in LS, and in pixel
          $('#loader').fadeOut()
        }
      } else if (data.name === 'error') {
        // we don't have lead_id in LS, and in pixel
        $('#loader').fadeOut()
      }
    })
  }

  if (leadFromLS) {
    // if leadId in LS - try to simple process
    simpleProcessToFeed(leadFromLS, 'ls')
  } else {
    // try to get lead from another domain
    ccIframeInit()
  }

  function simpleProcessToFeed (leadId, reason) {
    let sendHubEvent = function () {
      let event = (reason === 'ls') ? 'abandoned_visit' : 'abandoned_visit_by_pxl'
      let params = {
        lead_id: leadId,
        state_type: event,
        token: localStorage.sentFirebaseMessagingToken || ''
      }
      hubEvent(event, params)
      $('#loader').fadeOut()
    }
    if (!window.simpleProcessEnabled) {
      sendHubEvent()
      return
    }
    let globalConfig = window.globalConfig || {}
    sbjs.init()
    console.log('sbjs current', sbjs.get.current)
    let params = {
      lead_id: leadId,
      utm_source: sbjs.get.current.src,
      utm_medium: sbjs.get.current.mdm,
      utm_campaign: sbjs.get.current.cmp,
      utm_content: sbjs.get.current.cnt,
      utm_term: sbjs.get.current.trm,
      provider: globalConfig.provider,
      country: globalConfig.country,
      domain: window.location.hostname,
      device: detectDevice(),
      fb_fpc: getCookie('_fbc'),
      fb_fbp: getCookie('_fbp')
    }
    $.ajax({
      url: 'https://crezu.net:8002/simple_process',
      type: 'post',
      dataType: 'json',
      data: params,
      success: function (data) {
        localStorage.setItem('leadId', data.data.lead_id)
        localStorage.setItem('customerName', data.data.customer.name)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: 'Auth' })
        window.dataLayer.push({ event: 'FirstStepSubmitted' })
        window.dataLayer.push({event: 'flowBranch', lead_id: data.data.lead_id, branch: 'sp-1'})
        pushFBEvent('track', 'Lead', {status: data.data.lead_status})
        window.location.href = `/offers?sub1=${data.data.lead_id}&sub2=feed`
      },
      error: function (e) {
        console.log('error:', e)
        sendHubEvent()
      }
    });
  }
}

export {simpleProcessInit}
