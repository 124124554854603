import sbjs from 'sourcebuster';
import 'babel-polyfill';
import {setCookie, getCookie, detectDevice, GetURLParameter, pushFBEvent, initTrackers} from './helpers';
import {initFirebase} from "./notifications";
import {smartlinkRedirect} from "./smartlink_redirect";

import $ from 'jquery';
import {simpleProcessInit} from './simpleProcess'

let isIndex2 = window.location.pathname.indexOf('/index2') > -1

window.$ = $;
window.getCookie = getCookie
window.setCookie = setCookie

// FB event when index page is loaded
if (window.location.pathname === '/') pushFBEvent('track', 'ViewContent', {content_name: 'Homepage'})

// delete checkbox from all landings
let checkboxWrapper = document.getElementsByClassName('main-block__form__checkbox-wrap')
if (checkboxWrapper.length) checkboxWrapper[0].style.display = 'none'

sbjs.init({
  lifetime: 3
});

$.ajax({
  url: '/index.json',
  type: 'get',
  dataType: 'json',
  complete: function (data) {
    const config = JSON.parse(data.responseText)
    window.globalConfig = config.global

    // check URL for SL params for redirect user from landing domain
    smartlinkRedirect()

    $(document).ready(function () {
      // init gtm, gtag etc.
      initTrackers(config, initFirebase)

      function auth() {
        $('.preloader-landing').removeClass('hidden')
        console.log("call auth")
        localStorage.removeItem('authAutocomplete')
        localStorage.setItem('amount', $('[name=loan_sum]').val())
        var postData = $('.form_index').serializeArray()
        var additionalData = {
          'device': detectDevice(),
          'loan_period': 30,
          'utm_source': GetURLParameter('utm_source') || sbjs.get.current.src,
          'utm_medium': GetURLParameter('utm_medium') || sbjs.get.current.mdm,
          'utm_campaign': GetURLParameter('utm_campaign') || sbjs.get.current.cmp,
          'utm_content': GetURLParameter('utm_content') || sbjs.get.current.cnt,
          'utm_term': GetURLParameter('utm_term') || sbjs.get.current.trm,
          'country': config.global.country,
          'provider': config.global.provider,
          'ga_client_id': ga_client_id,
          'gclid': gclid,
        }
        for (var key in additionalData) {
          postData.push({
            name: key,
            value: additionalData[key]
          })
        }
        $.ajax({
          url: `${config.global.backendurl}/simple-auth`,
          type: 'post',
          dataType: 'json',
          data: postData,
          success: function (data) {
            data.data.customer['loan_sum'] = $('[name=loan_sum]').val()
            data.data.customer['timestamp'] = Date.now() / 1000 | 0
            localStorage.setItem('authAutocomplete', JSON.stringify(data.data.customer))
            localStorage.setItem('leadId', data.data.lead_id)
            localStorage.setItem('lead_status', data.data.lead_status)
            console.log(data)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'simpleAuth'
            })
            window.location.href = '/reg'
          },
          error: function (e) {
            window.location.href = '/reg'
            if (e.responseText.indexOf('Duplicate customer') > -1) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'duplicateCustomer'
              })
            }
          }
        })
      }

      $('[name=phone]').val('')

      const reg = {
        phone: '\\+34\\s((6[0-9]{2})|(7[1-4]{1}[0-9]{1}))\\s[0-9]{3}\\s[0-9]{3}$',
        email: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
        year: '^((19)|20)[0-9]{2}$'
      }

      const flag = {
        phone: ($('[name=phone]').val() || "").length > 0,
        email: ($('[name=email]').val() || "").length > 0,
        year: $('[name=year]').val().length > 0
      }

      function validate(name, value) {
        let regPattern = new RegExp(reg[name])
        return regPattern.test(value)
      }

      let inputsSelector = $('[name=email], [name=phone], [name=year]')

      inputsSelector.on('blur', function () {
        let selector = $(this)
        let name = selector.attr('name')
        let value = selector.val()
        if (!validate(name, value) && value !== '') {
          selector.addClass("error")
          flag[name] = false
        }
      })

      inputsSelector.on('keyup', function () {
        let selector = $(this)
        let name = selector.attr('name')
        let value = selector.val()
        if (validate(name, value)) {
          selector.removeClass("error")
          flag[name] = true
        }
      })

      $('.to_reg').on('click', function (e) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'clickCTA'
        })
        console.log("click main form", flag)
        localStorage.setItem('dataFromFirstStep', JSON.stringify({
          amount: $('[name=loan_sum]').val(),
          email: $('[name=email]').val(),
          phone: $('[name=phone]').val(),
        }))
        e.preventDefault()
        $('.to_reg').prop('disabled', true)
        if ((flag.email || flag.phone) && flag.year) {
          auth()
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'clickCTAFilled'
          })
        } else if ((($('[name=email]').val() === '' || $('[name=phone]').val() === '') && $('[name=year]').val() === '') && !isIndex2) {
          window.location.href = "/reg"
        } else {
          $('.to_reg').prop('disabled', false)
          $('[name=year]').addClass("error").keyup()
          $('[name=email]').addClass("error").keyup()
          $('[name=phone]').addClass("error").keyup()
        }
      })
      $('.to_reg_simple').on('click', function (e) {
        console.log("click additional CTA button")
        e.preventDefault()
        window.location.href = "/reg"
      })

      simpleProcessInit()
    });
  }
})
