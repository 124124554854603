import 'inputmask'
import Fingerprint2 from 'fingerprintjs2'
import $ from "jquery";

function detectDevice () {
  if (navigator.userAgent.match(/Android/i)) {
    return 'Android'
  } else if (navigator.userAgent.match(/BlackBerry/i)) {
    return 'BlackBerry'
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return 'iOS'
  } else if (navigator.userAgent.match(/Macintosh/i)) {
    return 'MacOS'
  } else if (navigator.userAgent.match(/IEMobile/i)) {
    return 'WindowsPhone'
  } else {
    return 'Desktop'
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function GetURLParameter(sParam, url) {
  var sPageURL = (!url ? window.location.search.substring(1) : url);
  var sURLVariables = sPageURL.split('&'),
    firstUrlvar = sPageURL.split('?');
  for (var i = 0; i < sURLVariables.length; i++)
  {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == sParam)
    {
      return sParameterName[1];
    }
  }
  for (var i = 0; i < firstUrlvar.length; i++)
  {
    var sParameterName = firstUrlvar[i].split('=');
    if (sParameterName[0] == sParam)
    {
      return sParameterName[1].replace(/&\w+/gi, '');
    }
  }
}

function getSlData (lead, renderLenders) {
  $.ajax({
    url: `https://sl.crezu.net/sl-feed?lead_id=${lead}`,
    type: 'get',
    success: function (data) {
      var sl_lenders=[]
      for (var i in data) {
        sl_lenders.push(data[i].toLowerCase())
      }
      if (!sl_lenders || !sl_lenders.length) {
        getSlData(global.leadId)
      } else {
        renderLenders(sl_lenders)
      }
    },
    error: function (error) {
      getSlData(global.leadId)
    }
  });
}

function getDPI() {
  try {
    for (var i = 56; i < 2000; i++) {
      if (matchMedia("(max-resolution: " + i + "dpi)").matches === true) {
        return i
      }
    }
    return i
  } catch (e) {
    return 0
  }
}

function getScreenSize() {
  try {
    var dppx = window.devicePixelRatio ||
      (window.matchMedia && window.matchMedia("(min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches ? 2 : 1) || 1;
    return `${screen.width * dppx}x${screen.height * dppx}`
  } catch (e) {
    return 0
  }
}

function getFingerprint() {
  if (window.requestIdleCallback) {
    requestIdleCallback(function () {
      Fingerprint2.getV18({}, function (result, components) {
        window.user_fingerprint = result
      })
    })
  } else {
    setTimeout(function () {
      Fingerprint2.getV18({}, function (result, components) {
        window.user_fingerprint = result
      })
    }, 500)
  }
}

function genOfferBlock(template, offer, link, index, imgSrc) {
  var tags = ''
  for (var t in offer.tags) {
    tags += `<li class="why-us-tags-item">${offer.tags[t]}</li>`
  }
  var additional = ''
  for (var a in offer.additional) {
    additional += `<p>${offer.additional[a]}</p>`
  }
  let generator = {
    'new': `<div class="feeds-block__item-wrap ${index > 2 ? '  col-5' : ''}" style="box-sizing: border-box">
      <div class="feeds-block__item">
        <div class="feeds-block__item-link">
          <!-- img -->
          <div class="feeds-block__item-img">
              <img src="${imgSrc}" alt="">
          </div>
          <div class="feeds-block__item-content">
            <!-- tags -->
            <div class="feeds-block__item-tags">
              ${offer['first_loan_free'] ? `<div class="feeds-block__item-tags__tag zero${(globalConfig.country === 8) ? '-kz' : ''}"><p>${offer['first_loan_free_text']}</p></div>` : ''}
              ${offer['car_pledge'] ? `<div class="feeds-block__item-tags__tag car"><p>${offer['car_pledge_text']}</p></div>` : ''}
              ${offer['is_card'] ? `<div class="feeds-block__item-tags__tag card"><p>${offer['card_text']}</p></div>` : ''}
              ${offer['is_bank'] ? `<div class="feeds-block__item-tags__tag bank"><p>${offer['bank_text']}</p></div>` : ''}
            </div>
            <p class="feeds-block__item-content__title">${offer.amount_title}</p>
            <p class="feeds-block__item-content__amount">${offer.amount_body}</p>
          </div>
          <!-- button -->
          <div class="feeds-block__item-button">
            <a href="${link + '&sub4=' + index}" target="_blank" class="btn-lead">
              <button>
                <span>${offer.buttonText}</span>
              </button>
            </a>
          </div>
          <div class="feeds-block__item-license ${additional.length ? '' : 'hidden'}">
            ${additional}
          </div>
        </div>
      </div>
    </div>`,
    'casino': `<div class="feeds-block__item-wrap ${index > 2 ? 'col-5' : ''}">
        <div class="feeds-block__item">
          <a href="${link + '&sub4=' + index}" target="_blank" class="feeds-block__item-link promo btn-lead">
            <!-- img -->
            <div class="feeds-block__item-img">
              <img src="${imgSrc}" alt="">
            </div>
            
            <!-- button -->
            <div class="feeds-block__item-button">
              <button>
                <span>${offer.buttonText}</span>
              </button>
            </div>
          </a>
        </div>
      </div>`
  }
  return generator[template]
}

function shuffle(array) {
  array.sort(() => Math.random() - 0.5);
  return array
}

function getLawyer() {
  return `<link rel="stylesheet" href="https://cdn.crezu.net/native/css/lawyer.css">
          <!-- BANNER -->
          <a href="https://track.crezu.net/click?pid=2&offer_id=213&sub1={LEAD_ID}&sub2=feed" target="_blank"
             class="banner-lawyer btn-lead">
            <img src="https://cdn.crezu.net/iframe/pic/lawyer/banner_lawyer_img.jpg" alt=""
             class="banner-lawyer__img">
            <div class="banner-lawyer__content">
              <p>Доверься профессионалам</p>
              <p>Адвокат Онлайн</p>
            </div>
            <div class="banner-lawyer__logo-wrap">
                <img src="https://cdn.crezu.net/iframe/pic/lawyer/banner_lawyer_logo.jpg" alt="">
            </div>
          </a>`
}

function getLombard() {
  return `<link rel="stylesheet" href="https://cdn.crezu.net/native/css/lombard.css">
          <!-- BANNER -->
          <div class="lombBanner" style="margin: 10px">
          <a href="https://track.crezu.net/click?offer_id=71&pid=2&sub1={LEAD_ID}&sub2=feed" class="btn-lead">
            <!-- top -->
            <div class="lombBanner-top">
            <img src="https://cdn.crezu.net/iframe/pic/lombard/logoLomb.svg" alt="" class="lombBanner-top__logo">
            </div>
            <!-- body -->
            <div class="lombBanner-body">
            <!-- first -->
            <div class="lombBanner-body__item lombBanner-body__first">
              <div class="lombBanner-body__item-img">
              <img src="https://cdn.crezu.net/iframe/pic/lombard/woman.png" alt="">
              </div>
              <div class="lombBanner-body__item-p">
              <p>Empeña tus productos SIN perderlos y ¡obtén dinero en el acto!<br><b>100% garantizado</b></p>
              </div>
            </div>
            <!-- second -->
            <div class="lombBanner-body__item lombBanner-body__second">
              <div class="lombBanner-body__item-p">
              <p>Sube la foto y comprueba el precio en unos instantes</p>
              </div>
              <div class="lombBanner-body__item-img">
              <img src="https://cdn.crezu.net/iframe/pic/lombard/photo.png" alt="">
              </div>
            </div>
            </div>
          </a>
          </div>`
}
function getJuegos() {
  return `<link rel="stylesheet" href="https://cdn.crezu.net/native/css/juegos.css">
    <!-- BANNER -->
    <a href="https://track.crezu.net/click?offer_id=42&pid=2&sub1={LEAD_ID}&sub2=feed" target="_blank" class="btn-lead" style="width: 100%;text-decoration: none; margin: 10px">
    <div class="ventaBanner">
      <!-- logos -->
      <div class="ventaBanner-logos">
        <p>+18 | JUEGA CON RESPONSABILIDAD</p>
        <div class="ventaBanner-logos__wrap">
          <img src="https://cdn.crezu.net/iframe/pic/juegos/logo1.png" alt="" class="ventaBanner-logos__1">
          <img src="https://cdn.crezu.net/iframe/pic/juegos/logo2.png" alt="" class="ventaBanner-logos__2">
        </div>
      </div>
      <div class="ventaBanner-wrap">
        <!-- main -->
        <div class="ventaBanner-main">
          <img src="https://cdn.crezu.net/iframe/pic/juegos/3000.png" alt="" class="ventaBanner-main__3000">
          <div>
            <button class="ventaBanner-main__button">Juega ahora</button>
          </div>  
        </div>
        <!-- tablet -->
        <div class="ventaBanner-img">  
          <img src="https://cdn.crezu.net/iframe/pic/juegos/img.png" alt="" class="ventaBanner-img__tablet">
          <img src="https://cdn.crezu.net/iframe/pic/juegos/050.png" alt="" class="ventaBanner-img__050">
        </div>
        <!-- desktop -->
        <div class="ventaBanner-desktop">
          <img src="https://cdn.crezu.net/iframe/pic/juegos/050.png" alt="" class="ventaBanner-desktop__050">
          <button class="ventaBanner-desktop__button">Juega ahora</button>
        </div>  
      </div>
  </div></a>`
}

function pushFBEvent (track, event, data) {
  try {
    if (window.fbq) fbq(track, event, data)
  } catch (e) {
    console.warn(e)
  }
}

function addTagsController() {
  $(".feeds-block__item-tags__tag").on("touchstart", function() {
    $(".feeds-block__item-tags__tag").children('p').addClass("hidden");
    $(this).children('p').removeClass('hidden')
  });
}

function initTrackers (config, initFirebase) {
  if (!config.global.show_cookie_bar) {
    setCookie('cookiePolicyPolitics', 1)
  }

  if (config.global.gtm) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', config.global.gtm);
  }

  if (config.global.gtag) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', config.global.gtag);
  }

  window.gclid = GetURLParameter('gclid') ? GetURLParameter('gclid') : '';
  window.ga_client_id = '';

  setTimeout(function() {
    if (typeof(ga) != 'undefined')
      ga(function() {
        var trackers = ga.getAll();
        if (trackers) {
          ga_client_id = trackers[0].get('clientId')
        }
      });
    window.back_fcm = config.global.backendurl
    window.ga_fcm = ga_client_id
    try {
      initFirebase()
    } catch (e) {
      console.error(e)
    }
  }, 1000);
}

function addPhoneMask (mask) {
  let im = new Inputmask({mask: mask, showMaskOnFocus: false, positionCaretOnClick: 'radixFocus'})
  im.mask(document.getElementsByName("phone"))
}

function addSumMask (sumSeparator) {
  let im = new Inputmask('decimal', {
    groupSeparator: sumSeparator,
    radixPoint: "/",
    groupSize: 3,
    rightAlign: false
  })
  im.mask(document.getElementById("form__slider-input"))
}

function setDefaultValues (defAmount, defPhone) {
  let oldData = localStorage.getItem('dataFromFirstStep') || '{}'
  oldData = JSON.parse(oldData)
  if (+window.globalConfig.country === 9 && oldData.phone && oldData.phone.indexOf('+84') > -1) oldData.phone = oldData.phone.split('+84')[1]
  if (+window.globalConfig.country === 4 && oldData['ar_phone_code'] && oldData['ar_phone']) {
    $('[name=ar-phone-code]').val(oldData['ar_phone_code'])
    $('[name=ar-phone]').val(oldData['ar_phone'])
  }
  if (+window.globalConfig.country !== 4) $('[name=phone]').val(oldData.phone || defPhone)
  $('[name=loan_sum]').val(oldData.amount || defAmount).trigger('input')
}

function newAmountControllers (sumSeparator) {
  // DROPDOWN AMOUNT
  function closeSelect () {
    $('.form__dropdown-select').removeClass('active');
    $('.form__dropdown-ul').removeClass('open');
    $('.form__dropdown-select__arrow').removeClass('open');
  }
  $(window).on('click', function (e) {
    if (typeof e.target.className === 'string' && e.target.className.indexOf('drpdwn_cntrl') === -1 )
      closeSelect()
  })
  $('.form__dropdown-ul li').on('click', function () {
    let val = $(this).attr('value')
    let valWithCurrency = $(this).text()
    calcular(+$("#month").text(), +val, +$("#tin").text());
    $('#form__slider-input').val(val)
    $('.form__dropdown-select__amount').text(valWithCurrency)
  })
  $('.form__dropdown-select').on('click', function () {
    console.log('drpdwn click')
    if ($('.form__dropdown-ul').hasClass('open')) {
      console.log('close drpdwn')
      closeSelect()
    } else {
      console.log('open drpdwn')
      $(this).addClass('active');
      $('.form__dropdown-ul').addClass('open');
      $('.form__dropdown-select__arrow').addClass('open');
    }
  });

  // RANGE SLIDER INPUT
  function selectRange(range, rangeValue, minValue, maxValue) {
    const rangeSlider = document.getElementById('form__slider-range');
    const amount = $('#form__slider-input');
    const sliderChange = () => {
      const x = (rangeSlider.value - minValue) / (maxValue - minValue) * 100;
      let mainColor = $('.form__button').css('background-color')
      if ($('.pixel').length) mainColor = $('.pixel').css('background-color')
      const color = `linear-gradient(90deg, ${mainColor} ${x}%, #f8f8f8 ${x}%)`;
      rangeSlider.style.background = color;
    };

    rangeSlider.addEventListener('input', function () {
      amount[0].value = this.value;
      sliderChange();
    });
    rangeSlider.addEventListener('mousemove', function () {
      sliderChange();
    });
    rangeSlider.addEventListener('touchmove', function () {
      sliderChange();
    });
    rangeSlider.addEventListener('click', function () {
      sliderChange();
    });
    rangeSlider.addEventListener('touchend', function () {
      sliderChange();
    });
    rangeSlider.addEventListener('touchstart', function () {
      sliderChange();
    });

    amount.on('input', function () {
      let value = this.value.split(sumSeparator).join('')
      rangeSlider.value = value
      $('.form__dropdown-ul li').each(function () {
        if ($(this).attr('value') == value) $(this).trigger('click')
      })
      sliderChange();
    });

    amount.on('blur', function () {
      let value = this.value.split(sumSeparator).join('')
      if (value < window.globalConfig.min_loan_amount) {
        value = window.globalConfig.min_loan_amount;
      } else if (value > window.globalConfig.max_loan_amount) {
        value = window.globalConfig.max_loan_amount;
      } else {
        value = this.value;
      }
      amount.val(value).trigger('input')
    })

    sliderChange()
  }
  selectRange('range', 'value', window.globalConfig.min_loan_amount, window.globalConfig.max_loan_amount);

  function inputResizer (val) {
    $('#form__slider-input').css('width', ((val.length + 2.5) * 8) + 5 + 'px')
  }
  function number_format (number, decimals, dec_point, thousands_sep) {
    number = number.toFixed(decimals)
    var nstr = number.toString()
    nstr += ''
    var x = nstr.split('.')
    var x1 = x[0]
    var x2 = x.length > 1 ? dec_point + x[1] : ''
    var rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) x1 = x1.replace(rgx, '$1' + thousands_sep + '$2')
    return x1 + x2
  }

  function calcular(duration, amount, tin){
    if (typeof amount === 'string') amount = +(amount.split(sumSeparator).join(''))
    if (!duration || !tin || !amount) return
    var meses=duration
    var cuotames=(amount*((tin/100)/12))/(1-(Math.pow(1+((tin/100)/12),-meses)))
    var fCuota_mes = number_format(cuotames, 2, ',', '.')
    var fImp_devol = number_format(cuotames*meses, 2, ',', '.')
    $("#month_pay").html(fCuota_mes)
    $("#total_loan_value").html(fImp_devol)
  }

  $('#form__slider-input, #form__slider-range').on('input click mousemove touchmove touchstart touchend', function () {
    // resize input
    inputResizer($(this).val())
    // calculate simulator
    calcular(+$("#month").text(), $(this).val(), +$("#tin").text());
  })
}

function cookieBar (config) {
  //COOKIES START
  $("body").append(`<div class="cookies-info" style="display: none"><p>${config.info.cookiesText} <a href="/${config.info.cookiesLink}" target="_blank">${config.info.cookiesMoreText}</a>.</p><button id="agreeCookiePolitics">${config.info.cookiesAcceptText}</button></div>`);
  $(".cookies-info button").click(function(){
    setCookie("cookiePolicyPolitics",1,365);
    $(".cookies-info").hide()
  });
  1!=getCookie("cookiePolicyPolitics")&&$(".cookies-info").show();

  if (config.global.countryId !== 1) {
    setTimeout(function(){
      $(".cookies-info").hide();
      setCookie("cookiePolicyPolitics",1,365);
    }, 10000);
  } else {
    console.log('disable autohide cpp in 10s')
  }
}

function hubEvent(eventName, params) {
  // check if we already sent similar event
  if (getCookie(eventName)) return
  // write cookie for prevent next similar events
  setCookie(eventName, 1, 1)
  $.ajax({
    url: 'https://crezu.net:8002/webhook',
    type: 'post',
    dataType: 'json',
    data: params
  });
}

const crezuEvent = (data) => {
  try {
    fetch('https://events.crezu.net/api/event', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  } catch (e) {}
}

const generateUUIDv4 = () => {
  try {
    const uuidv4 = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
    setCookie('uuidv4', uuidv4, 1 / 48)
    return uuidv4
  } catch (e) {}
}

export {setCookie, getCookie, detectDevice, GetURLParameter, getDPI, getScreenSize, getFingerprint, genOfferBlock, getSlData, shuffle, getLawyer, getLombard, getJuegos, pushFBEvent, addTagsController, initTrackers, addPhoneMask, newAmountControllers, cookieBar, setDefaultValues, hubEvent, addSumMask, crezuEvent, generateUUIDv4}
