import firebase	from 'firebase/app'
import 'firebase/messaging'
import sbjs from 'sourcebuster'
import { promptTexts } from './promptTexts'
try {window.$ = jQuery} catch (e) {}

let firebaseConfig = {
	apiKey: "AIzaSyCOKQQTd3aq5wGHnfnapSKDli0waqA372k",
	authDomain: "crezu-api.firebaseapp.com",
	databaseURL: "https://crezu-api.firebaseio.com",
	projectId: "crezu-api",
	storageBucket: "crezu-api.appspot.com",
	messagingSenderId: "1085106671775",
	appId: "1:1085106671775:web:5f96eb67e90c58dc9c12aa"
};
firebase.initializeApp(firebaseConfig);
let messaging

function initFirebase() {
	let permissionGranted = false
	if ('Notification' in window) {
		permissionGranted = Notification.permission === 'granted'
	}

	$('<link/>', {
		   rel: 'stylesheet',
		   type: 'text/css',
		   href: 'https://cdn.morecashpls.com/landings/css/push-v3.css'
		}).appendTo('head');
	// prepare prompt and show it
	window.showPushPrompt = function () {
		let pushWrap = ''
		try {
			let pushMessage = globalConfig.customText || promptTexts[globalConfig.country]().pushMessage
			let acceptText = globalConfig.customAcceptText || promptTexts[globalConfig.country]().acceptText
			let declineText = globalConfig.customDeclineText || promptTexts[globalConfig.country]().declineText
			let img = globalConfig.customImg || 'https://cdn.morecashpls.com/landings/img/i-push-01.svg'
			let ifWindows = navigator.platform.indexOf('Win') > -1
			let acceptButton = `<button class="btn-notify btn-notify-allow accept-fcm">${acceptText}</button>`
			pushWrap = `<div class="push active">
				<div class="push-body">
				<a href="#" class="push-body-close"><img src="https://cdn.morecashpls.com/landings/img/i-push-close.svg" width="9px" height="9px" alt="close" class="push-close decline-fcm"></a>
				<div>
				<img src="${img}" width="64px" height="68px" alt="money" class="push-icon push-icon__money">
				</div>
				<div class="push-body-content">
				<p>${pushMessage}</p>
				</div>
				<div class="notify-body-btns">
				${ifWindows ? (acceptButton + '<span></span>') : ''}
				<button class="btn-notify btn-notify-cancel decline-fcm">${declineText}</button>
				${ifWindows ? '' : ('<span></span>' + acceptButton)}
				</div>
				<img src="https://cdn.morecashpls.com/landings/img/img-push-arrow.png" width="680px" height="231px" alt="arrow" class="push-arrow">
				</div>
				</div>
				${ifWindows ? '<style>@media (min-width: 1080px){.push-arrow {left: -430px;transform: scaleX(0.8)}}</style>' : ''}`

		} catch (e) {
			console.log('problems with notification text prompt')
		}

		function hidePrompt() {
			$(".push").removeClass("active");
			localStorage.setItem('sentFirebaseMessagingToken', 'CANCELLED BY USER')
			localStorage.setItem('updatedFirebaseMessagingToken', 'CANCELLED BY USER')
		}

		// show prompt if new user
		// call getToken if user is returned (previously he confirm our prompt), but token is not set end equal 'CONFIRMED BY USER'
		let storageToken = localStorage.getItem('sentFirebaseMessagingToken')
		// if ((!storageToken || storageToken === 'CONFIRMED BY USER') && firebase.messaging.isSupported() && !permissionGranted) {
		if (!storageToken && firebase.messaging.isSupported() && !permissionGranted) {
			if (pushWrap) {
				$('body').append(pushWrap)
			} else {
				callNativePrompt()
			}
		} else if (storageToken === 'CONFIRMED BY USER' && !permissionGranted) {
			callNativePrompt()
		} else if (permissionGranted && storageToken !== 'CANCELLED BY USER') {
			callNativePrompt()
		}

		$(".accept-fcm").on("click",function(){
			callNativePrompt();
			$(".push").removeClass("active");
			localStorage.setItem('sentFirebaseMessagingToken', 'CONFIRMED BY USER')
		})
		$(".decline-fcm").on("click",function(){
			hidePrompt()
		});
	}
	showPushPrompt()
}
function callNativePrompt() {
	if (firebase.messaging.isSupported()) {
		try {
			messaging = firebase.messaging();
			console.log('firebase init')
			getToken()

			// handle catch the notification on current page
			messaging.onMessage(function (payload) {
				console.log('Message received', payload);

				// register fake ServiceWorker for show notification on mobile devices
				navigator.serviceWorker.register('/firebase-messaging-sw.js');
				Notification.requestPermission(function (permission) {
					if (permission === 'granted') {
						navigator.serviceWorker.ready.then(function (registration) {
							// Copy data object to get parameters in the click handler
							try {
								payload.data.data = JSON.parse(JSON.stringify(payload.data));
							} catch (e) {
								payload.data = payload.notification
								payload.data.data = payload.notification
							}
							registration.showNotification(payload.data.title, payload.data);
						}).catch(function (error) {
							// registration failed :(
							console.log('ServiceWorker registration failed', error);
						});
					}
				});
			});

			// Callback fired if Instance ID token is updated.
			messaging.onTokenRefresh(function () {
				messaging.getToken()
					.then(function (refreshedToken) {
						console.log('Token refreshed');
						// Send Instance ID token to app server.
						sendTokenToServer(refreshedToken);
					})
					.catch(function (error) {
						console.log('Unable to retrieve refreshed token', error);
					});
			});
		} catch (e) {
			console.log('error in handler', e)
		}
	} else {
		if (!('Notification' in window)) {
			console.log('Notification not supported');
		} else if (!('serviceWorker' in navigator)) {
			console.log('ServiceWorker not supported');
		} else if (!('localStorage' in window)) {
			console.log('LocalStorage not supported');
		} else if (!('fetch' in window)) {
			console.log('fetch not supported');
		} else if (!('postMessage' in window)) {
			console.log('postMessage not supported');
		}

		console.warn('This browser does not support desktop Notification.');
		console.log('Is HTTPS', window.location.protocol === 'https:');
		console.log('Support Notification', 'Notification' in window);
		console.log('Support ServiceWorker', 'serviceWorker' in navigator);
		console.log('Support LocalStorage', 'localStorage' in window);
		console.log('Support fetch', 'fetch' in window);
		console.log('Support postMessage', 'postMessage' in window);
	}
}


function getToken() {
	messaging.requestPermission()
		.then(function () {
			window.localStorage.setItem('requestFCMAccepted', 'true');
			// Get Instance ID token. Initially this makes a network call, once retrieved
			// subsequent calls to getToken will return from cache.
			messaging.getToken()
				.then(function (currentToken) {
					if (currentToken) {
						sendTokenToServer(currentToken);
					} else {
						console.log('No Instance ID token available. Request permission to generate one');
						setTokenSentToServer(false);
					}
				})
				.catch(function (error) {
					console.log('An error occurred while retrieving token', error);
					setTokenSentToServer(false);
				});
		})
		.catch(function (error) {
			console.log('Unable to get permission to notify', error);
		});
}

function GetURLParameter(sParam, url) {
	var sPageURL = (!url ? window.location.search.substring(1) : url);
	var sURLVariables = sPageURL.split('&'),
		firstUrlvar = sPageURL.split('?');
	for (var i = 0; i < sURLVariables.length; i++)
	{
		var sParameterName = sURLVariables[i].split('=');
		if (sParameterName[0] == sParam)
		{
			return sParameterName[1];
		}
	}
	for (var i = 0; i < firstUrlvar.length; i++)
	{
		var sParameterName = firstUrlvar[i].split('=');
		if (sParameterName[0] == sParam)
		{
			return sParameterName[1].replace(/&\w+/gi, '');
		}
	}
}

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(currentToken) {
	if (!isTokenSentToServer(currentToken)) {
		console.log('Sending token to server...', currentToken);
		let reserveData = {}
		try {
			reserveData = {
				country: document.getElementsByName("country")[0].value,
				provider: document.getElementsByName("provider")[0].value
			}
		} catch (e) {

		}
		if (GetURLParameter('f_l')) {
			console.log('don\'t init sbjs')
			sbjs.init({domain: 'f_l'})
		} else {
			console.log('init sbjs')
			sbjs.init({})
		}
		$.ajax({
			url: `${window.back_fcm}/set-token`,
			type: 'post',
			dataType: 'json',
			data: {
				token: currentToken,
				ga_client_id: window.ga_fcm,
				domain: window.location.hostname,
				lead_id: localStorage.getItem('leadId'),
				country: globalConfig.country || reserveData.country,
				provider: globalConfig.provider || reserveData.provider,
				leadFromSendPulse: localStorage.getItem('leadFromSendPulse_v2') || '0',
				utm_source: globalConfig.src || sbjs.get.current.src,
				utm_medium: globalConfig.mdm || sbjs.get.current.mdm,
				utm_campaign: globalConfig.cmp || sbjs.get.current.cmp,
				utm_content: globalConfig.cnt || sbjs.get.current.cnt,
				utm_term: globalConfig.trm || sbjs.get.current.trm
			},
			success: function (data) {
				setTokenSentToServer(currentToken);
			},
			error: function (e) {
				console.warn('/set-token is unsuccessfull')
			}
		})
	} else {
		console.log('Token already sent to server so won\'t send it again unless it changes', currentToken);
	}
}

function isTokenSentToServer(currentToken) {
	return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken;
}

function setTokenSentToServer(currentToken) {
	if (currentToken) {
		window.localStorage.setItem('sentFirebaseMessagingToken', currentToken);
	} else {
		window.localStorage.removeItem('sentFirebaseMessagingToken');
	}
}

export {initFirebase, getToken, callNativePrompt}
