const promptTexts = {
	ES: {
		pushMessage: 'Permita las notificaciones para consultar el estado de la transferencia de su dinero.',
		acceptText: 'Permitir',
		declineText: 'Rechazar',
	},
	EN: {
		pushMessage: 'Allow notifications to track the status of a money transfer.',
		acceptText: 'Allow',
		declineText: 'Reject',
	},
	UA: {
		pushMessage: 'Дозвольте повідомлення, щоб відстежувати статус переказу коштів.',
		acceptText: 'Дозволити',
		declineText: 'Відхилити',
	},
	RU: {
		pushMessage: 'Разрешите уведомления, чтобы отслеживать статус перевода денежных средств.',
		acceptText: 'Разрешить',
		declineText: 'Отклонить',
	},
	VN: {
		pushMessage: 'Đồng ý nhận thông báo để theo dõi trạng thái chuyển tiền.',
		acceptText: 'Đồng ý',
		declineText: 'Từ chối',
	},
	LK: {
		pushMessage: 'මුදල් හුවමාරුවක තත්වය නිරීක්ෂණය කිරීමට දැනුම් දීම් වලට ඉඩ දෙන්න.',
		acceptText: 'ඉඩ දෙන්න',
		declineText: 'ප්‍රතික්ෂේප කරන්න',
	},
	EE: {
		pushMessage: 'Lubage meeldetuletusi, et jälgida raha ülekande kulgu.',
		acceptText: 'Luban',
		declineText: 'Keeldun',
	},
	RO: {
		pushMessage: 'Permite notificările pentru a urmări starea unui transfer de bani.',
		acceptText: 'Permite',
		declineText: 'Respinge',
	},
	LT: {
		pushMessage: 'Leiskite pranešimus, kad galėtumėte sekti pinigų pervedimo būklę.',
		acceptText: 'Leisti',
		declineText: 'Atmesti',
	},
	PL: {
		pushMessage: 'Wyraź zgodę na notyfikacje aby śledzić status przelewu pieniędzy do Ciebie.',
		acceptText: 'Wyrażam zgodę',
		declineText: 'Odrzucać',
	},
	LV: {
		pushMessage: 'Atļaut paziņojumus, lai sekotu naudas pārskaitījuma statusam.',
		acceptText: 'Atļaut',
		declineText: 'Noraidīt',
	},
	BG: {
		pushMessage: 'Разреши известията, за да следиш статуса на паричния си трансфер.',
		acceptText: 'Разрешавам',
		declineText: 'Отказвам',
	},
	1: function() { return this.ES },
	2: function() { return this.ES },
	3: function() { return this.EN },
	4: function() { return this.ES },
	5: function() { return this.ES },
	6: function() { return this.ES },
	7: function() { return this.UA },
	8: function() { return this.RU },
	9: function() { return this.VN },
	10: function() { return this.LK },
	11: function() { return this.EE },
	12: function() { return this.RO },
	13: function() { return this.LT },
	14: function() { return this.PL },
	15: function() { return this.LV },
	16: function() { return this.BG },
}

export { promptTexts }
