import {GetURLParameter} from './helpers';

function smartlinkRedirect() {
  function showLoader() {
    document.body.insertAdjacentHTML("afterBegin", `<div id="loader_url">
      <style>
          #loader_url{display:block;position:fixed;background:#fff;top:0px;left:0px;width:100vw;height:100vh;z-index:999}.lds-ring{display:inline-block;position:absolute;top:calc(50vh - 32px);left:50%;-webkit-transform:translate(-50%,-50%);-moz-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);-o-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:64px;height:64px}.lds-ring div{-webkit-box-sizing:border-box;box-sizing:border-box;display:block;position:absolute;width:51px;height:51px;margin:6px;border:6px solid #333;border-radius:50%;-webkit-animation:lds-ring 1.2s cubic-bezier(.5,0,.5,1) infinite;animation:lds-ring 1.2s cubic-bezier(.5,0,.5,1) infinite;border-color:#333 transparent transparent transparent}.lds-ring div:first-child{-webkit-animation-delay:-.45s;animation-delay:-.45s}.lds-ring div:nth-child(2){-webkit-animation-delay:-.3s;animation-delay:-.3s}.lds-ring div:nth-child(3){-webkit-animation-delay:-.15s;animation-delay:-.15s}@-webkit-keyframes lds-ring{0%{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes lds-ring{0%{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
      </style>
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>`
    );
  }

  function hideLoader() {
    let loader = document.querySelector("#loader_url")
    if (loader) loader.remove()
  }

  function successRedirectPrepare(url) {
    window.onbeforeunload = null;
    window.is_redirected = true;
    window.location.href = url
    let loader = document.querySelector("#loader_url")
    setTimeout(function(){ if (loader) loader.remove() },5000)
  }

  function getUrlByCountry(country) {
    let countriesDict = {
      '1': 'https://crezu.es',
      '2': 'https://crezu.mx',
      '3': 'https://crezu.ph',
      '4': 'https://crezu.com.ar',
      '5': 'https://crezu.pe',
      '6': 'https://crezu.co',
      '7': 'https://crezu.ua',
      '8': 'https://crezu.kz',
      '9': 'https://crezu.vn',
    }
    return countriesDict[country]
  }

  // sl params
  let sl_sub2 = GetURLParameter('sub2')
  let sl_lead_id = GetURLParameter('lead_id')
  let sl_landing = GetURLParameter('landing')
  let sl_selection = GetURLParameter('selection')
  let sl_offer_id = GetURLParameter('offer_id')

  //unsub params
  let sl_lead_unsub = GetURLParameter('lead_unsub')

  let loader = document.querySelector("#loader_url")

  let sl_url = (globalConfig.country !== 3) ? "https://sl.crezu.net/" : "https://sl.iiziloan.com/"
  let unsub_url = (globalConfig.country !== 3) ? "https://unsub.crezu.net/" : "https://unsub.iiziloan.com/"

  if (sl_sub2 && sl_lead_id && sl_landing && !sl_lead_unsub) {
    let redirect_link = `${sl_url}crm?sub2=${sl_sub2}&element=1&lead_id=${sl_lead_id}&landing=${sl_landing}
    ${sl_selection ? `&selection=${sl_selection}` : ``}${sl_offer_id ? `&offer_id=${sl_offer_id}` : ``}`
    if (sl_landing === 'landing-mail' || sl_landing === 'landing-push' || sl_landing === 'landing-sms') {
      redirect_link = `${getUrlByCountry(globalConfig.country)}/${sl_landing.split('-').join('/')}/?sub1=${sl_lead_id}&sub2=${sl_sub2}`
    } else if (sl_landing === 'landing-push') {
      redirect_link = `${getUrlByCountry(globalConfig.country)}/landing/push/?sub1=${sl_lead_id}&sub2=${sl_sub2}`
    }
    successRedirectPrepare(redirect_link)
    if (!loader) showLoader()
  } else if (!sl_sub2 && !sl_lead_id && !sl_landing && sl_lead_unsub) {
    successRedirectPrepare(`${unsub_url}email/${sl_lead_unsub}`)
    if (!loader) showLoader()
  } else {
    hideLoader()
  }
}

export {smartlinkRedirect}